import { useEffect, useState, useRef } from "react";
import moment from "moment";
import AppointmentsApi from "../../../services/AppointmentsApi";
import { FaClinicMedical } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import Loader from "../Loader";
import { Link } from "react-router-dom";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { Modal } from 'react-bootstrap';
import CompletePayment from "../../Report/partial/CompletePayment";
import AuthApi from "../../../services/AuthApi";
import { MainSelect } from "../../../mainComponent/mainSelect";
import { Drawer, IconButton, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function DuePayments(props) {
    const { doctorId, onChange } = props
    const [patientHistoryData, setPatientHistoryData] = useState(null)
    const [clinics, setClinics] = useState([]);
    const [selectedClinic, setSelectedClinic] = useState(null)
    const [reportId, setReportId] = useState()
    const [appointmentId, setAppointmentId] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const { getAppointmentsDetails } = AppointmentsApi()
    const [doctors, setDoctors] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null)
    const [show, setShow] = useState(false);
    const { getDrInfo } = AuthApi();
    const clinicRef = useRef(selectedClinic);
    const paginationRef = useRef(currentPage);
    const doctorRef = useRef(selectedDoctor);
    const [open, setOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:768px)')

    useEffect(() => {
        getPatientHistory(selectedClinic, currentPage, selectedDoctor);
        getAllClinics()
    }, []);

    const handleClose = () => {
        setOpen(false)
        setShow(false);
    }

    const handleShow = (details) => {
        if (details) {
            setReportId(details.medicalReportId)
            setAppointmentId(details._id)
            setShow(true);
            setOpen(true)
        }
    }

    const pageSize = 6;

    const getAllClinics = () => {
        getDrInfo({ doctorId })
            .then((jsonRes) => {
                const clinicsData = jsonRes.result[0]["clinicList"]
                const doctorsData = jsonRes.result[0]["doctorList"]
                doctorsData.push(jsonRes.result[0])
                setClinics(clinicsData)
                setDoctors(doctorsData)
            })
    }
    function getPatientHistory(selectedClinic, currentPage, selectedDoctor) {
        const data = {
            page: currentPage,
            pageSize: pageSize,
            payment: "Pending",
            selectedClinic: selectedClinic,
            selectedDoctor: selectedDoctor
        }
        setIsLoading(true);
        getAppointmentsDetails(doctorId, data)
            .then((result) => {
                if (result) {
                    setTotalPages(result.totalPages)
                    setPatientHistoryData(result.pageIndex)
                }
            })
            .catch((error) => {
                console.error("Failed to fetch data", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handlePageClick = (data) => {
        paginationRef.current = data.selected + 1;
        setCurrentPage(data.selected + 1)
        getPatientHistory(selectedClinic, data.selected + 1, selectedDoctor)
    }

    const handleClinicChange = (e) => {
        clinicRef.current = e.target.value;
        setSelectedClinic(e.target.value);
        getPatientHistory(e.target.value, currentPage, selectedDoctor)
    };

    const handleDoctorChange = (e) => {
        doctorRef.current = e.target.value;
        const doctorId = e.target.value;
        setSelectedDoctor(doctorId);
        getPatientHistory(selectedClinic, currentPage, doctorId)
    };
    return (
        <>
            {isLoading ?
                <div className='loader-container'>
                    <Loader />
                </div>
                :
                <>
                    <div className="white-box mb-2">
                        <div className='row' align='left'>
                            <div className='mr-4'>
                                <label className='mb-2 font_weight' value="lab">Find Appointments by Clinic</label>
                                <div className='mt-2'>
                                    <MainSelect value={selectedClinic} onChange={handleClinicChange}>
                                        <option value="">Select Clinic</option>
                                        {clinics && clinics.map((item, index) => (
                                            <option key={index} value={item.clinicName} className="form-control">{item.clinicName}</option>
                                        ))}
                                    </MainSelect>
                                </div>
                            </div>
                            <div className='mr-4'>
                                <label className='mb-2 font_weight' value="lab">Find Appointments by doctor</label>
                                <div className='mt-2'>
                                    <MainSelect value={selectedDoctor} onChange={handleDoctorChange}>
                                        <option value="">Select doctor</option>
                                        {doctors && doctors.map((item, index) => (
                                            <option key={index} value={item._id} className="form-control">{item.name}</option>
                                        ))}
                                    </MainSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                    {patientHistoryData && patientHistoryData.length > 0 ?
                        <>
                            <div className='row'>
                                {patientHistoryData && patientHistoryData.map((details, i) => {
                                    return (
                                        <>
                                            {!details.dependentId ?
                                                <div key={i} className="col-md-4 ">
                                                    <div className="cardDiv">
                                                        <span className='cardSpan'>
                                                            <i className='icon-user color patientListIcon' />
                                                            <span className='patientName'>{details['patientDetails'][0].name}</span>
                                                        </span>
                                                        <span className='cardSpan'>
                                                            <i className='icon-mobile-1 color patientListIcon' />
                                                            <span className='patinetInfo'>{details['patientDetails'][0].mobile}</span>
                                                        </span>
                                                        <span className='cardSpan '>
                                                            <i className=' color patientListIcon ml-1 mr-2' ><FaClinicMedical /> </i>
                                                            <span className='patinetInfo '> {details['clinicList'].clinicName}</span>
                                                        </span>
                                                        <span className='cardSpan time'>
                                                            <i className='pe-7s-date m-1 color patientListIcon' />
                                                            <span className='slotTime'>
                                                                {moment(details.selectedDate).format('YYYY-MM-DD').toString()},
                                                                {details.slotTime}
                                                            </span>
                                                        </span>
                                                        <div className="row justify-end top_border">
                                                            <div className="mt-3 mr-2">
                                                                <Link onClick={() => handleShow(details)}>
                                                                    <MainButtonInput>Pay Now</MainButtonInput>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div key={i} className="col-md-4 ">
                                                    <div className="cardDiv">
                                                        <div className='cardSpan row '>
                                                            <div align='left' className='width_70' >
                                                                <i className=' icon-user color patientListIcon' />
                                                                <span className=' patientName'>{details['dependentDetails'][0].name}</span>
                                                            </div>
                                                            <div className='width_10' align='left'>
                                                                <span className='dependent'>Dependent</span>
                                                            </div>
                                                        </div>
                                                        <span className='cardSpan'>
                                                            <i className='icon-mobile-1 color patientListIcon' />
                                                            <span className='patinetInfo'>{details['patientDetails'][0].mobile}</span>
                                                        </span>
                                                        <span className='cardSpan '>
                                                            <i className=' color patientListIcon ml-1 mr-2' ><FaClinicMedical /> </i>
                                                            <span className='patinetInfo '> {details['clinicList'].clinicName}</span>
                                                        </span>
                                                        <span className='cardSpan time'>
                                                            <i className='pe-7s-date m-1 color patientListIcon' />
                                                            <span className='slotTime'>
                                                                {moment(details.selectedDate).format('YYYY-MM-DD').toString()},{details.slotTime}
                                                            </span>
                                                        </span>

                                                        <div className="row justify-end top_border">
                                                            <div className="mt-3 mr-2">
                                                                <Link onClick={() => handleShow(details)}>
                                                                    <MainButtonInput>Pay Now</MainButtonInput>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )

                                })}

                            </div>
                            <div className="marginleft">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="Next >"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={totalPages}
                                    previousLabel="< Previous"
                                    renderOnZeroPageCount={null}
                                    marginPagesDisplayed={2}
                                    containerClassName="pagination "
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                    forcePage={currentPage - 1}
                                />
                            </div>
                        </>
                        : <div className="clinicHistory font_weight">
                            Appointments are not available
                        </div>}
                </>
            }
            {isMobile ? (
                <Drawer anchor="bottom" open={open} onClose={handleClose}>
                    <div className='drawerTitle underline' >
                        <Typography variant="h6">Payment</Typography>
                        <IconButton onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className='p-4'>
                        <CompletePayment
                            doctorId={doctorId}
                            appointmentId={appointmentId}
                            reportId={reportId}
                            onSubmit={handleClose}
                            onChange={onChange}
                        />
                    </div>
                </Drawer>
            ) : (
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Payment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CompletePayment
                            doctorId={doctorId}
                            appointmentId={appointmentId}
                            reportId={reportId}
                            onSubmit={handleClose}
                            onChange={onChange}
                        />
                    </Modal.Body>
                </Modal>
            )
            }
        </>
    )
}