import React, { useState, useEffect } from "react";
import avatarImage from '../../../img/profile.png';
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { MainInput } from '../../../mainComponent/mainInput';
import AuthApi from "../../../services/AuthApi";
import SubscriptionApi from "../../../services/SubscriptionApi";
import { useRecoilState } from "recoil";
import { setsubscriptionId } from "../../../recoil/atom/setSubscriptionId";
import { SecondaryButtonInput } from "../../../mainComponent/secondaryButtonInput";
import Toaster from "../../Toaster";
import "react-toastify/dist/ReactToastify.css";
import { API } from "../../../config";
import { toast } from "react-toastify";
import axios from "axios";

function DoctorPersonalInformation(props) {
    const { data, doctorId } = props;
    const [updateData, setUpdateData] = useState([]);
    const [file, setFile] = useState(null);
    const { FetchDoctorData } = AuthApi();

    useEffect(() => {
        addDrInfo();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUpdateData({ ...updateData, [name]: value });
    };

    const addDrInfo = () => {
        FetchDoctorData({ doctorId })
            .then(jsonRes => {
                setUpdateData(jsonRes);
            });
    };

    const handleFileChange = (event) => {
        setUpdateData({ ...updateData, ['photo']: URL.createObjectURL(event.target.files[0]) })
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', updateData.name);
        formData.append('gender', updateData.gender);
        formData.append('personalEmail', updateData.personalEmail);
        formData.append('address', updateData.address);
        formData.append('photo', file);
        formData.append('bio', updateData.bio);
        try {
            const response = await axios.post(`${API}/insertPersonalInfo/${doctorId}`, formData, {
                headers: { "Content-Type": "multipart/form-data" }
            });
            // const result = await response.json();
            // await subscriptionPDF(subscriptionId);
        } catch (error) {
            console.error("Error:", error);
        }
        toast.success('Data save successfully')
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-7">
                        <div className="row mb-10">
                            <div className="col-md-5">
                                <div className="doctorphoto">
                                    {updateData.photo ?
                                        <img src={updateData.photo} className="doctorphotoStyle" alt="doctorPhoto" />
                                        : <img src={avatarImage} alt="doctorPhoto" className="doctorphotoStyle" />
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="text-left">
                                    <label className="font_weight">Doctor photo</label>
                                </div>
                                <MainInput
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={handleFileChange}
                                    name="photo"
                                />
                            </div>
                        </div>
                        <div className="text-left">
                            <label className="font_weight">Gender *</label>
                        </div>
                        <div className="col-sm-6 radioButton" align='left'>
                            <input
                                className="radio_button"
                                type="radio"
                                value='female'
                                name="gender"
                                onChange={handleInputChange}
                                checked={updateData.gender === 'female'}
                            />
                            <span>Female</span>
                            <input
                                className="radio_button"
                                type="radio"
                                value='male'
                                name="gender"
                                checked={updateData.gender === 'male'}
                                onChange={handleInputChange}
                            />
                            <span>Male</span>
                            <input
                                className="radio_button"
                                type="radio"
                                value='other'
                                name="gender"
                                checked={updateData.gender === 'other'}
                                onChange={handleInputChange}
                            />
                            <span>Other</span>
                        </div>
                        <div className="text-left">
                            <label className="font_weight">Namespace *</label>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <MainInput
                                    type="text"
                                    value={updateData.namespace}
                                    name="namespace"
                                    onChange={handleInputChange}
                                    placeholder="namespace"
                                />
                            </div>
                            <div className="text-left col-md-9">
                                <span>.wecurify.com</span>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="text-left">
                            <label className="font_weight" htmlFor="name">Full Name *</label>
                        </div>
                        <MainInput
                            name="name"
                            value={updateData.name}
                            onChange={handleInputChange}
                            placeholder="Name"
                        />
                        <div className="text-left">
                            <label className="font_weight" htmlFor="email">EmailId *</label>
                        </div>
                        <MainInput
                            type="email"
                            value={updateData.personalEmail}
                            name="personalEmail"
                            onChange={handleInputChange}
                            placeholder="emailId"
                        />
                        <div className="text-left">
                            <label className="font_weight" htmlFor="address">City & Area *</label>
                        </div>
                        <div align='left'>
                            <MainInput
                                name='address'
                                value={updateData.address}
                                placeholder="Address"
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="text-left">
                            <label className="font_weight" htmlFor="bio">Bio*</label>
                        </div>
                        <div align='left'>
                            <textarea name='bio'
                                value={updateData.bio}
                                placeholder="Bio"
                                className="form-control"
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mt-3 float-right">
                    <div className="text-left">
                        <MainButtonInput> Save</MainButtonInput>
                    </div>
                    <div className="text-left ml-2">
                        <SecondaryButtonInput onClick={data}>Next</SecondaryButtonInput>
                    </div>
                </div>
            </form>
            <div className="row float-right">
                <Toaster />
            </div>
        </>
    );
}

export { DoctorPersonalInformation };
