import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import avatarImage from '../../../img/profile.png';
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { MainInput } from '../../../mainComponent/mainInput';
import { useRecoilState } from "recoil";
import Toaster from "../../Toaster";
import "react-toastify/dist/ReactToastify.css";
import { API } from "../../../config";
import { toast } from "react-toastify";
import axios from "axios";
import { Wrapper } from "../../../mainComponent/Wrapper";
import { MainNav } from "../../../mainComponent/mainNav";
import UserLinks from "../../Dashboard-card/partial/uselinks";
import { setHelperData } from "../../../recoil/atom/setHelperData";
import { setDoctorId } from "../../../recoil/atom/setDoctorId";
import AuthApi from "../../../services/AuthApi";

function UpdateNewDoctor() {
    const { id } = useParams();
    const doctorId = id
    const [updateData, setUpdateData] = useState([]);
    const [helperData, setHelpeData] = useRecoilState(setHelperData)
    const [DoctorId] = useRecoilState(setDoctorId)
    const [file, setFile] = useState(null);
    const { FetchDoctorData } = AuthApi();
    const navigate = useNavigate();

    useEffect(() => {
        addDrInfo()
    }, []);

    const addDrInfo = () => {
        FetchDoctorData({ doctorId })
            .then(jsonRes => {
                setUpdateData(jsonRes);
            });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUpdateData({ ...updateData, [name]: value });
    };

    const handleFileChange = (event) => {
        setUpdateData({ ...updateData, ['photo']: URL.createObjectURL(event.target.files[0]) })
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', updateData.name);
        formData.append('gender', updateData.gender);
        formData.append('personalEmail', updateData.personalEmail);
        formData.append('address', updateData.address);
        formData.append('photo', file);
        formData.append('bio', updateData.bio);
        formData.append('DoctorId', DoctorId);
        try {
            const response = await axios.post(`${API}/addnewdoctor/${doctorId}`, formData, {
                headers: { "Content-Type": "multipart/form-data" }
            });
            const result = await response.data
        } catch (error) {
            console.error("Error:", error);
        }
        toast.success('Data save successfully')
        navigate(`/newdoctor/${DoctorId}`)
    };

    return (
        <>
            <Wrapper>
                <MainNav>
                    <ul className="clearfix">
                        <li className='float-none' style={{ fontSize: 'inherit' }} >Update Doctor</li>
                    </ul>
                </MainNav>
                <div className="row ">
                    <div className='width_16'>
                        <div className='dash row'>
                            <UserLinks
                                doctorId={doctorId}
                                helperId={helperData._id}
                                accessModule={helperData.access_module}
                            />
                        </div>
                    </div>
                    <div className='width_84'>
                        <div className='white-box pb-5'>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="row mb-10">
                                            <div className="col-md-5">
                                                <div className="doctorphoto">
                                                    {updateData.photo ?
                                                        <img src={updateData.photo} className="doctorphotoStyle" alt="doctorPhoto" />
                                                        : <img src={avatarImage} alt="doctorPhoto" className="doctorphotoStyle" />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="text-left">
                                                    <label className="font_weight">Doctor photo</label>
                                                </div>
                                                <MainInput
                                                    type="file"
                                                    accept=".png, .jpg, .jpeg"
                                                    onChange={handleFileChange}
                                                    name="photo"
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-12">
                                            <div className="col-md-6">
                                                <div className="text-left">
                                                    <label className="font_weight">Gender *</label>
                                                </div>
                                                <div className="radioButton" align='left'>
                                                    <input
                                                        className="radio_button"
                                                        type="radio"
                                                        value='female'
                                                        name="gender"
                                                        onChange={handleInputChange}
                                                        checked={updateData.gender === 'female'}
                                                    />
                                                    <span>Female</span>
                                                    <input
                                                        className="radio_button"
                                                        type="radio"
                                                        value='male'
                                                        name="gender"
                                                        checked={updateData.gender === 'male'}
                                                        onChange={handleInputChange}
                                                    />
                                                    <span>Male</span>
                                                    <input
                                                        className="radio_button"
                                                        type="radio"
                                                        value='other'
                                                        name="gender"
                                                        checked={updateData.gender === 'other'}
                                                        onChange={handleInputChange}
                                                    />
                                                    <span>Other</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-left">
                                            <label className="font_weight" htmlFor="address">City & Area *</label>
                                        </div>
                                        <div align='left'>
                                            <MainInput
                                                name='address'
                                                value={updateData.address}
                                                placeholder="Address"
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="text-left">
                                            <label className="font_weight" htmlFor="name">Full Name *</label>
                                        </div>
                                        <MainInput
                                            name="name"
                                            value={updateData.name}
                                            onChange={handleInputChange}
                                            placeholder="Name"
                                        />
                                        <div className="text-left">
                                            <label className="font_weight" htmlFor="email">EmailId *</label>
                                        </div>
                                        <MainInput
                                            type="email"
                                            value={updateData.personalEmail}
                                            name="personalEmail"
                                            onChange={handleInputChange}
                                            placeholder="emailId"
                                        />
                                        <div className="text-left">
                                            <label className="font_weight" htmlFor="bio">Bio*</label>
                                        </div>
                                        <div align='left'>
                                            <textarea name='bio'
                                                value={updateData.bio}
                                                placeholder="Bio"
                                                className="form-control"
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mr-2 float-right">
                                    <MainButtonInput> Save</MainButtonInput>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row float-right">
                        <Toaster />
                    </div>
                </div>
            </Wrapper>
        </>
    );
}

export { UpdateNewDoctor };
