import React, { useEffect, useState, useRef } from "react";
import { setHelperData } from "../../../recoil/atom/setHelperData";
import { Wrapper } from '../../../mainComponent/Wrapper';
import { useRecoilState } from "recoil";
import { Link, useParams } from 'react-router-dom';
import { MainNav } from '../../../mainComponent/mainNav';
import UserLinks from '../partial/uselinks';
import { Button, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import VisibilityIcon from '@mui/icons-material/Visibility';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import Loader from '../Loader';
import { Drawer, IconButton, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddInventoryModalBox from "./AddInventoryModalBox";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { setClinicId } from "../../../recoil/atom/setClinicId";
import InventoryApi from "../../../services/InventoryApi";
import ViewInventory from "./ViewInventory";
import { ModeEditOutlineSharp } from "@mui/icons-material";
import EditInwards from "./EditInwards";
import { MainInputBox } from "../../../mainComponent/mainInput";
import { setInventoryDetails } from "../../../recoil/atom/setInventoryData";
import { MainSelect } from "../../../mainComponent/mainSelect";
import AuthApi from "../../../services/AuthApi";

export default function Inwards() {
    const { doctorId } = useParams();
    const [helpersData] = useRecoilState(setHelperData)
    const [inwardData, setInwardData] = useRecoilState(setInventoryDetails)
    const [viewInwardData, setViewInwardData] = useState(null)
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const [showData, setShowData] = useState(false);
    const [viewData, setViewData] = useState(false);
    const [editView, setEditView] = useState(false);
    const isMobile = useMediaQuery('(max-width:  768px)')
    const [clinicId] = useRecoilState(setClinicId);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [clinics, setClinics] = useState([]);
    const [selectedClinic, setSelectedClinic] = useState('');
    const paginationRef = useRef(currentPage);
    const [activeModal, setActiveModal] = useState();
    const [removeStockModal, setRemoveStockModal] = useState(false)
    const [itemToRemove, setItemToRemove] = useState([])
    const [quantityToRemove, setQuantityToRemove] = useState(0)
    const pageSize = 10;
    const clinicRef = useRef(selectedClinic);
    const { getInwardDetails, addOutwardData, updateInwardData } = InventoryApi();
    const { getDrInfo } = AuthApi();

    useEffect(() => {
        getInwardData(currentPage, selectedClinic);
        getAllClinics()
    }, [])

    const getInwardData = (currentPage, selectedClinic) => {
        setIsLoading(true)
        const data = {
            page: currentPage,
            pageSize: pageSize,
            selectedClinic: selectedClinic
        }
        getInwardDetails(doctorId, data)
            .then((result) => {
                setTotalPages(result.totalPages)
                setInwardData(result.clinicData)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getAllClinics = () => {
        getDrInfo({ doctorId })
            .then((jsonRes) => {
                const clinicsData = jsonRes.result[0]["clinicList"]
                setClinics(clinicsData)
            })
    }

    const handleClinicChange = (e) => {
        clinicRef.current = e.target.value;
        setSelectedClinic(e.target.value);
        getInwardData(currentPage, e.target.value)
    };

    const handlePageClick = (data) => {
        paginationRef.current = data.selected + 1
        setCurrentPage(data.selected + 1)
        getInwardData(data.selected + 1, selectedClinic)
    }

    const handleDataClose = () => {
        setShowData(false)
    }
    const handleDataShow = () => {
        setShowData(true)
    }

    const handleViewClose = () => {
        setViewData(false)
    }

    const handleView = (item) => {
        setViewInwardData(item)
        setViewData(true)
    }
    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };
    const openDrawerWithModal = (modalType, item) => {
        setActiveModal(modalType);
        setViewInwardData(item)
        setDrawerOpen(true);
    };
    const handleEditOpen = (item) => {
        setViewInwardData(item)
        setEditView(true)
    }
    const handleEditClose = (item) => {
        setEditView(false)
    }

    const handleRemoveStockOpen = (item) => {
        setItemToRemove(item);
        setRemoveStockModal(true);
    };

    const handleRemoveStockClose = () => {
        setRemoveStockModal(false);
        setQuantityToRemove(0);
    };

    const handleUpdateStock = () => {
        const enteredQuantity = parseInt(quantityToRemove, 10);

        if (isNaN(enteredQuantity) || enteredQuantity <= 0) {
            alert("Please enter a valid quantity to remove.");
            return;
        }

        if (enteredQuantity > itemToRemove.quantity) {
            alert("Not enough stock available to remove.");
            return;
        }
        const updatedInward = inwardData.map((inwardItem) => {
            if (inwardItem._id === itemToRemove._id) {
                const quantity = inwardItem.quantity - enteredQuantity;
                return {
                    ...inwardItem,
                    quantity: quantity
                };
            }
            return inwardItem;
        });
        const updatedItem = updatedInward.find(item => item._id === itemToRemove._id);
        if (updatedItem) {
            updateInwardData(itemToRemove._id, updatedItem)
            handleRemoveStockClose();
        }
        const inwardId = itemToRemove._id
        const bodyData = {
            outwardDate: new Date(),
            quantity: enteredQuantity,
        }
        addOutwardData(inwardId, bodyData)
    };

    const renderModalContent = () => {
        switch (activeModal) {
            case 'inventorymodalbox':
                return <AddInventoryModalBox
                    doctorId={doctorId}
                    onSubmit={handleDataClose} />
            case 'viewinventory':
                return <ViewInventory
                    doctorId={doctorId}
                    clinicId={clinicId}
                    viewInwardData={viewInwardData}
                    onSubmit={handleViewClose}
                />
            case 'editinwards':
                return <EditInwards
                    doctorId={doctorId}
                    // clinicId={clinicId}
                    inwardId={viewInwardData}
                    onSubmit={handleEditClose}
                />
            default:
                return null;
        }
    };
    const getDrawerTitle = () => {
        switch (activeModal) {
            case 'inventorymodalbox':
                return 'Add Inward Details';
            case 'viewinventory':
                return 'View Inward Details';
            case 'editinwards':
                return 'Edit Inward Details';
            default:
                return '';
        }
    };


    return (
        <>
            <Wrapper>
                <MainNav>
                    <ul className="clearfix">
                        <li className='float-none'
                            style={{ fontSize: 'inherit' }}>
                            Inward Report</li>
                        <li>
                            <div align='right'>
                                <Button
                                    className='appColor btn_sub'
                                    onClick={() => isMobile ? openDrawerWithModal('inventorymodalbox') : handleDataShow()}>
                                    Add Inwards
                                </Button>
                            </div>
                        </li>
                    </ul>
                </MainNav>

                <div className='row'>
                    <div className='width_16'>
                        <div className='dash row'>
                            <UserLinks
                                doctorId={doctorId}
                                helperId={helpersData._id}
                                accessModule={helpersData.access_module}
                            />
                        </div>
                    </div>

                    <div className='width_84'>
                        {isLoading ?
                            <div className='loader-container'>
                                <Loader />
                            </div>
                            :
                            <div className='common_box'>
                                <div className="white-box mb-2">
                                    <div className='row' align='left'>
                                        <div className='mr-4'>
                                            <label className='mb-2 font_weight' value="lab">Find Appointments by Clinic</label>
                                            <div className='mt-2'>
                                                <MainSelect value={selectedClinic} onChange={handleClinicChange}>
                                                    <option value="">Select Clinic</option>
                                                    {clinics && clinics.map((item, index) => (
                                                        <option key={index} value={item.clinicName} className="form-control">{item.clinicName}</option>
                                                    ))}
                                                </MainSelect>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {inwardData && inwardData.length > 0 ?
                                    <>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className="font_weight" align="left">Serial No</TableCell>
                                                        <TableCell className="font_weight" align="left">Item Name</TableCell>
                                                        <TableCell className="font_weight" align="left">Manufacturer Name</TableCell>
                                                        <TableCell className="font_weight" align="left">Expiry Date</TableCell>
                                                        <TableCell className="font_weight" align="left">Stock</TableCell>
                                                        <TableCell className="font_weight" align="left">Remaining Stock</TableCell>
                                                        <TableCell className="font_weight" align="left">Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {inwardData && inwardData.map((item, index) => {
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell align="left">
                                                                    {(currentPage - 1) * pageSize + (index + 1)}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {item.itemName}
                                                                </TableCell>

                                                                <TableCell align="left">
                                                                    {item.manufacturerName}
                                                                </TableCell>

                                                                <TableCell align="left">
                                                                    {moment(item.expiryDate).format("DD-MM-YYYY")}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {item.quantity}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {item.remainingStock ? item.remainingStock : '--'}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    <Link onClick={() => isMobile ? openDrawerWithModal('viewinventory', item) : handleView(item)}>
                                                                        <VisibilityIcon style={{ fontSize: 20 }} />
                                                                    </Link>
                                                                    <Link onClick={() => isMobile ? openDrawerWithModal('editinwards', item._id) : handleEditOpen(item._id)}>
                                                                        <ModeEditOutlineSharp style={{ marginLeft: 15, fontSize: 20 }} />
                                                                    </Link>
                                                                    <Link onClick={() => handleRemoveStockOpen(item)}>
                                                                        <RemoveCircleIcon style={{ marginLeft: 15, fontSize: 20 }} />
                                                                    </Link>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <div className='mt-5'>
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="Next >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={totalPages}
                                                previousLabel="< Previous"
                                                renderOnZeroPageCount={null}
                                                marginPagesDisplayed={2}
                                                containerClassName="pagination "
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                activeClassName="active"
                                                forcePage={currentPage - 1}
                                            />
                                        </div>
                                    </> :
                                    <div className="clinicHistory font_weight" align='center' >
                                        Inwards details are not available
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </Wrapper>
            {isMobile ? (
                <Drawer anchor="bottom" open={drawerOpen} onClose={handleDrawerToggle}>
                    <div className='drawerTitle underline' >
                        <Typography variant="h6">
                            {getDrawerTitle()}
                        </Typography>
                        <IconButton onClick={handleDrawerToggle} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className='p-4'>
                        {renderModalContent()}
                    </div>
                </Drawer>
            ) : (
                <>
                    <div className="modalbtn">
                        <Modal show={showData} onHide={handleDataClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Inward Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <AddInventoryModalBox
                                    doctorId={doctorId}
                                    onSubmit={handleDataClose} />
                            </Modal.Body>
                        </Modal>
                    </div>
                    <Modal show={viewData} onHide={handleViewClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Inward Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ViewInventory
                                doctorId={doctorId}
                                clinicId={clinicId}
                                viewInwardData={viewInwardData}
                                onSubmit={handleViewClose}
                            />
                        </Modal.Body>
                    </Modal>
                    <Modal show={editView} onHide={handleEditClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Inward Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <EditInwards
                                doctorId={doctorId}
                                clinicId={clinicId}
                                inwardId={viewInwardData}
                                onSubmit={handleEditClose}
                            />
                        </Modal.Body>
                    </Modal>
                </>
            )}
            {isMobile ? (
                <Drawer anchor="bottom" open={removeStockModal} onClose={handleRemoveStockClose}>
                    <div className='drawerTitle underline' >
                        <Typography variant="h6">Enter Quantity</Typography>
                        <IconButton onClick={handleRemoveStockClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className="p-4">
                        <div>
                            <label className="font_weight">Item Name&nbsp;:</label>&nbsp;&nbsp;
                            {itemToRemove ? itemToRemove.itemName : ''}
                        </div>

                        <div>
                            <label className="font_weight">Extract  Quantity&nbsp;:</label>&nbsp;&nbsp;
                            <MainInputBox
                                type="number"
                                value={quantityToRemove}
                                onChange={(e) => setQuantityToRemove(e.target.value)}
                                min="1"
                                max={itemToRemove ? itemToRemove.quantity : 0}
                            />
                        </div>
                        <div align='right' >
                            <Button className="appColor" onClick={() => handleUpdateStock(itemToRemove)}>
                                Update Stock
                            </Button>
                        </div>
                    </div>
                </Drawer>
            ) : (
                <>
                    <Modal show={removeStockModal} onHide={handleRemoveStockClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Enter Quantity</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <label className="font_weight">Item Name&nbsp;:</label>&nbsp;&nbsp;
                                {itemToRemove ? itemToRemove.itemName : ''}
                            </div>

                            <div>
                                <label className="font_weight">Extract  Quantity&nbsp;:</label>&nbsp;&nbsp;
                                <MainInputBox
                                    type="number"
                                    value={quantityToRemove}
                                    onChange={(e) => setQuantityToRemove(e.target.value)}
                                    min="1"
                                    max={itemToRemove ? itemToRemove.quantity : 0}
                                />
                            </div>
                            <div align='right' >
                                <Button className="appColor" onClick={() => handleUpdateStock(itemToRemove)}>
                                    Update Stock
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            )}
        </>
    )
}